import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, CircularProgress, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import CmpAgenda from './CmpAgenda';
import CtrlAgenda from './CtrlAgenda';
import ConfirmBox from '../ConfirmBox';

import { meetingService } from '../../Redux/services';

import './Agenda.css';

var now = new Date();

require('moment/locale/fr.js');

var colors = {
    'color-1': "rgba(102, 195, 131 , 1)",
    "color-2": "rgba(242, 177, 52, 1)",
    "color-3": "rgba(235, 85, 59, 1)",
    "color-4": "rgba(70, 159, 213, 1)",
    "color-5": "rgba(170, 59, 123, 1)"
}

export function Agenda() {
    const patients = useSelector((state) => state.user.patients);
    const [items, setItems] = useState([])
    const [selected, setSelected] = useState([])
    const cellHeight = 12
    const [showModal, setShowModal] = useState(false)
    const rowsPerHour = 4
    const [numberOfDays, setNumberOfDays] = useState(window.innerWidth > 900 ? 3 : 1)
    const [startDate, setStartDate] = useState(new Date())
    const user = useSelector((state) => state.user)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [patientId, setPatientId] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [view, setView] = useState('calendar')

    useEffect(() => {
        if (user.demo) {
            const sessionData = sessionStorage.getItem('meetings');
            let data = JSON.parse(sessionData, (key, value) => {
                if (key === 'startDateTime' || key === 'endDateTime') {
                    return new Date(value);
                }
                return value;
            });
            if (!data) {
                import('../../Helpers/dataDemo')
                    .then((module) => {
                        data = module.getMeetings(user._id);
                        sessionStorage.setItem('meetings', JSON.stringify(data));
                        setItems(data);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        console.error("Erreur lors de l'import du module conditionnel :", error);
                    });
            }
            else {
                setItems(data);
                setLoaded(true);
            };
        }
        else {
            var tmp = []
            meetingService.getByUserId(user.userId).then((resp) => {
                resp.forEach((element, index) => {
                    const thisPatient = patients ? patients[patients.findIndex((e) => { return e._id === element.patientId })] : null;
                    tmp.push({
                        _id: element._id,
                        name: thisPatient ? thisPatient.firstname + ' ' + thisPatient.name : '',
                        startDateTime: new Date(element.startDateTime),
                        endDateTime: new Date(element.endDateTime),
                        classes: element.classes,
                        patientId: element.patientId,
                        userId: element.userId
                    })
                });
                setItems(tmp)
                setLoaded(true)
            })
        }
    }, [])

    useEffect(() => {
        if (user.demo) {
            sessionStorage.setItem('meetings', JSON.stringify(items))
        }
    }, [items])

    const changeView = (event, days, type) => {
        setView(type)
        setNumberOfDays(days)
    }

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        const el = document.getElementsByClassName('agenda__cell_selected')[0];
        if (el)
            el.classList.remove('agenda__cell_selected')
        setSelected([])
        setShowModal(false)
    }

    const handleItemEdit = (item, open) => {
        if (item && open === true) {
            setSelected([item])
            return openModal();
        }
    }

    const handleItemVisit = (item) => {
        setPatientId(item.patientId)
        setOpenConfirm(true)
    }

    const handleConfirm = (patientId) => {
        window.location.href = `/patients/${patientId}`
    }

    const handleCellSelection = (item) => {
        setSelected([item]);
        openModal();
    }

    const handleDateRangeChange = (startDate, endDate) => {
        setStartDate(startDate)
    }

    const handleRangeSelection = (selected) => {
        setSelected(selected)
        openModal();
    }

    const handleItemChange = (items, item) => {
        setItems(items)
    }

    const handleItemSize = (items, item) => {
        setItems(items)
    }

    const addNewEvent = (newItem) => {
        meetingService.create(newItem).then((resp) => {
            addItem(newItem, resp.id)
        }).catch(error => {
            addItem(newItem, uuidv4());
            sessionStorage.setItem('meetings', JSON.stringify(items))
        });
    }

    const editEvent = (item) => {
        meetingService.update(item).then(() => {
            updateItem(item)
        }).catch(error => {
            updateItem(item);
        });
    }

    const deleteEvent = (item) => {
        meetingService.delete(item._id).then(() => {
            deleteItem(item)
        }).catch(error => {
            deleteItem(item);
        });
    }

    const addItem = (item, id) => {
        let updatedItems = [...items];
        let newItem = item;
        newItem._id = id;
        updatedItems.push(newItem)
        setShowModal(false)
        setSelected([])
        setItems(updatedItems);
        closeModal();
    }

    const updateItem = (item) => {
        let updatedItems = [...items];
        let i = items.findIndex((element) => { return element._id === item._id })
        if (i !== -1)
            updatedItems[i] = item;
        setShowModal(false)
        setSelected([])
        setItems(updatedItems);
        closeModal();
    }

    const deleteItem = (item) => {
        let updatedItems = [...items];
        let i = items.findIndex((element) => { return element._id === item._id })
        if (i !== -1)
            updatedItems.splice(i, 1);
        setShowModal(false)
        setSelected([])
        setItems(updatedItems);
        closeModal();
    }

    return (
        loaded ?
            <div>
                <div className="control-buttons">
                    <IconButton
                        aria-label="add"
                        onClick={() => openModal()}
                    >
                        <AddIcon />
                    </IconButton>
                    {window.innerWidth > 900 ?
                        <>
                            <button className="button-control" onClick={() => changeView(null, 1, "list")}>{moment.duration(1, "day").humanize()}</button>
                            <button className="button-control" onClick={() => changeView(null, 3, "calendar")}> {moment.duration(3, "days").humanize()}  </button>
                            <button className="button-control" onClick={() => changeView(null, 7, "calendar")}> {moment.duration(7, "days").humanize()}  </button>
                        </>
                        :
                        <>
                            <button className="button-control" onClick={() => changeView(null, 1, "list")}>Vue liste</button>
                            <button className="button-control" onClick={() => changeView(null, 1, "calendar")}> {moment.duration(1, "day").humanize()} </button>
                            <button className="button-control" onClick={() => changeView(null, 3, "calendar")}> {moment.duration(3, "days").humanize()}  </button>
                        </>
                    }
                </div>
                <CmpAgenda
                    minDate={new Date(now.getFullYear(), now.getMonth() - 3)}
                    maxDate={new Date(now.getFullYear(), now.getMonth() + 3)}
                    startDate={startDate}
                    startAtTime={7}
                    endAtTime={21}
                    cellHeight={cellHeight}
                    locale="fr"
                    items={items}
                    numberOfDays={numberOfDays}
                    headFormat={"ddd DD MMM"}
                    rowsPerHour={rowsPerHour}
                    itemColors={colors}
                    helper={true}
                    view={view}
                    autoScale={false}
                    fixedHeader={true}
                    onRangeSelection={handleRangeSelection}
                    onChangeEvent={handleItemChange}
                    onChangeDuration={handleItemSize}
                    onItemEdit={handleItemEdit}
                    onItemVisit={handleItemVisit}
                    onCellSelect={handleCellSelection}
                    onItemRemove={deleteEvent}
                    onDateRangeChange={handleDateRangeChange} />
                {
                    openConfirm ?
                        <ConfirmBox open={true} onConfirm={() => handleConfirm(patientId)} text="Voulez-vous vraiment être redirigé vers la page patient correspondante ?" />
                        : null
                }
                <Modal
                    open={showModal}
                    onClose={closeModal}
                >
                    <CtrlAgenda patients={patients} itemColors={colors} selectedCells={selected} Addnew={addNewEvent} onClose={closeModal} edit={editEvent} delete={deleteEvent} userId={user.userId} slotTime={user.slotTime} />
                </Modal>
            </div>
            :
            <div className='loading-wrapper'>
                <CircularProgress
                    classes={{
                        colorPrimary: 'loading-color',
                    }} />
            </div>
    );
}