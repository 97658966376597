import React from 'react';

import { Agenda } from "../../Components"
import './Home.css'

export default function Home() {
    return (
        <div className="home-container">
            <Agenda />
        </div>
    );
}