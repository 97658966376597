import storage from 'redux-persist/lib/storage';
import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const userService = {
    login,
    logout,
    register,
    requestResetPassword,
    resetPassword,
    getAll,
    getById,
    getNameById,
    update,
    delete: _delete,
    getPatients,
    getProviderToken
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    return fetch(`${REACT_APP_API_URL}/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${REACT_APP_API_URL}/users/signup`, requestOptions).then(handleResponse);
}

function requestResetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    return fetch(`${REACT_APP_API_URL}/users/requestResetPassword`, requestOptions).then(handleResponse);
}

function logout() {
    localStorage.removeItem('user');
    sessionStorage.clear();
    storage.removeItem('persist:root');
    window.location.href = '/login';
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

function getNameById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users/name/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${REACT_APP_API_URL}/users/${user._id}`, requestOptions).then(handleResponse);;
}

function resetPassword(id, password) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, password })
    };
    return fetch(`${REACT_APP_API_URL}/users/resetPassword`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

// PATIENTS FUNCTIONS

function getPatients(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users/${id}/patients`, requestOptions).then(handleResponse);
}

// PROVIDER FUNCTIONS

function getProviderToken(id, name) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${REACT_APP_API_URL}/users/${id}/token/${name}`, requestOptions).then(handleResponse);
}