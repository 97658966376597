import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, Select, MenuItem, IconButton, Autocomplete } from '@mui/material';
import { Delete } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid';

import { AdviceCard } from '../../Components'
import { adviceService } from '../../Redux/services';
import { RootState, AdviceState, PatientState } from '../../Types';

import './Advice.css'

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function Advice() {
    const user = useSelector((state: RootState) => state.user);
    const [items, setItems] = useState<Array<AdviceState>>([])
    const [itemsFiltered, setItemsFiltered] = useState<Array<AdviceState>>(items)
    const [itemParams, setItemParams] = useState<AdviceState>({
        _id: null,
        title: '',
        description: '',
        type: 0,
        userId: user.userId
    });
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState<boolean>(false);
    const [openMail, setOpenMail] = useState<boolean>(false);
    const [itemMail, setItemMail] = useState<AdviceState | null>(null);
    const [mailParams, setMailParams] = useState({ toMail: null, precision: '' });
    const [patients, setPatients] = useState(useSelector((state: RootState) => state.user.patients));
    const [searched, setSearched] = useState({ text: '', contact: true, video: true, advice: true });

    useEffect(() => {
        if (user.demo) {
            const sessionData = sessionStorage.getItem('advices');
            let data = JSON.parse(sessionData);
            if (!data) {
                adviceService.getByUserId(user.userId).then((resp) => {
                    setItems(resp)
                })
                sessionStorage.setItem('advices', JSON.stringify(data));
            }
            else {
                setItems(data);
            };
        }
        else {
            adviceService.getByUserId(user.userId).then((resp) => {
                setItems(resp)
            })
        }
    }, [user.userId])

    useEffect(() => {
        const filteredItems = items.filter((element) => {
            if (element.type === 0 && searched.contact) {
                return true;
            }
            if (element.type === 1 && searched.video) {
                return true;
            }
            if (element.type === 2 && searched.advice) {
                return true;
            }
            return false;
        }).filter((element) => {
            const lowerCaseText = searched.text.toLowerCase();
            return (
                element.title.toLowerCase().includes(lowerCaseText) ||
                element.description.toLowerCase().includes(lowerCaseText)
            );
        });
        setItemsFiltered(filteredItems);
    }, [items, searched])

    useEffect(() => {
        if (user.demo) {
            sessionStorage.setItem('advices', JSON.stringify(items))
        }
    }, [items])

    useEffect(() => {
        if (patients) {
            const updatedPatients: PatientState[] = patients.filter((element) => {
                if (element.mail) {
                    return true;
                }
                return false;
            })
            setPatients(updatedPatients);
        }
    }, [])

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, element?: AdviceState) => {
        event.preventDefault();
        if (element)
            setItemParams(element)
        else {
            setItemParams({
                _id: null,
                title: '',
                description: '',
                type: 0,
                userId: user.userId
            })
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target.id ? event.target.id : event.target.name
        setItemParams({ ...itemParams, [target]: event.target.value });
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        itemParams._id ?
            adviceService.update(itemParams).then(resp => {
                updateItem();
            }).catch(error => {
                updateItem();
            }) :
            adviceService.create(itemParams).then(resp => {
                addItem(resp.id);
            }).catch(error => {
                addItem(uuidv4());
            });
        handleClose();
    }

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        adviceService.delete(itemParams._id).then(resp => {
            deleteItem();
        }).catch(error => {
            deleteItem();
        });
        handleClose();
    }

    const addItem = (id: string) => {
        let newItem = itemParams;
        newItem._id = id;
        setItems(items => [...items, newItem])
    }

    const updateItem = () => {
        let updatedItems = [...items];
        let i = items.findIndex((element) => { return element._id === itemParams._id })
        if (i !== -1)
            updatedItems[i] = itemParams;
        setItems(updatedItems);
    }

    const deleteItem = () => {
        let updatedItems = [...items];
        let i = items.findIndex((element) => { return element._id === itemParams._id })
        if (i !== -1)
            updatedItems.splice(i, 1);
        setItems(updatedItems);
    }

    const renderBody = (
        <div style={modalStyle} className="adviceModalContainer">
            <div className="adviceModalRow">
                <TextField id="title" label="Titre" fullWidth value={itemParams.title} onChange={handleChange} />
                <FormControl className='adviceModalSelect'>
                    <InputLabel id="select-label">Type</InputLabel>
                    <Select
                        labelId="select-label"
                        id="type"
                        name="type"
                        label="Type"
                        defaultValue={itemParams.type} onChange={handleChange}
                    >
                        <MenuItem value={0}>Contact</MenuItem>
                        <MenuItem value={1}>Vidéo</MenuItem>
                        <MenuItem value={2}>Conseil</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <TextField id="description" label="Description" fullWidth value={itemParams.description} onChange={handleChange} multiline maxRows={5} minRows={5} />
            <button className="adviceModalButton" onClick={handleSubmit}>Sauvegarder</button>
            {itemParams._id ? <IconButton className="adviceModalButtonDelete" onClick={handleDelete}><Delete /></IconButton> : null}
        </div>
    )

    const handleOpenMail = (event: React.MouseEvent<HTMLButtonElement>, item?: AdviceState) => {
        event.preventDefault();
        setMailParams({ toMail: null, precision: '' });
        setItemMail(item);
        setOpenMail(true);
    };

    const handleCloseMail = () => {
        setOpenMail(false);
    };

    const handleChangeMailParams = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailParams({ ...mailParams, [event.target.id]: event.target.value });
    };

    const handleSend = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const params = {
            fromMail: 'contact@sesamoide.com',
            toMail: mailParams.toMail.mail,
            subject: itemMail.title,
            content: mailParams.precision ? mailParams.precision + '\n\n' : '' + itemMail.description
        }
        adviceService.sendMail(params).then((resp) => {
            handleCloseMail();
        })
    };

    const renderModalMail = (
        <div style={modalStyle} className="adviceModalContainer">
            <Autocomplete
                value={mailParams.toMail}
                fullWidth
                onChange={(e, newValue) => {
                    setMailParams({ ...mailParams, toMail: newValue });
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="toMail-autocomplete"
                options={patients}
                getOptionLabel={(option) => option.firstname + ' ' + option.name}
                renderOption={(props, option) => <li key={option._id} {...props}>{option.firstname + ' ' + option.name}</li>}
                renderInput={(params) => (
                    <TextField {...params} required label="Destinataire" fullWidth />
                )}
            />
            <TextField id="precision" label="Précisions" value={mailParams.precision} onChange={handleChangeMailParams} fullWidth multiline maxRows={5} minRows={5} />
            <button className="adviceModalButton" onClick={(e) => handleSend(e)}>Envoyer</button>
        </div>
    );

    return (
        <div className='adviceContainer'>
            <div className='adviceRow'>
                <FormControl className='adviceFilterField adviceFilterSearchField'>
                    <FormLabel id="search-label" style={{ width: '150px', color: "black" }}>Rechercher :</FormLabel>
                    <TextField id="search" variant="standard" fullWidth value={searched.text} onChange={(e) => setSearched({ ...searched, text: e.target.value })} />
                </FormControl>
                <FormGroup className='adviceFilterField'>
                    <FormControlLabel
                        control={
                            <Checkbox checked={searched.contact} onChange={() => setSearched({ ...searched, contact: !searched.contact })} name="contact" />
                        }
                        label="Contact"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={searched.video} onChange={() => setSearched({ ...searched, video: !searched.video })} name="video" />
                        }
                        label="Vidéo"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={searched.advice} onChange={() => setSearched({ ...searched, advice: !searched.advice })} name="conseil" />
                        }
                        label="Conseil"
                    />
                </FormGroup>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    {renderBody}
                </Modal>
                <Modal
                    open={openMail}
                    onClose={handleCloseMail}
                >
                    {renderModalMail}
                </Modal>
            </div>
            <div className='adviceContent'>
                <AdviceCard key={0} item={null} handleOpen={handleOpen} />
                {itemsFiltered.map((item, index) => {
                    return (
                        <AdviceCard key={index + 1} item={item} handleOpen={handleOpen} handleOpenMail={handleOpenMail} />
                    )
                })}
            </div>
        </div >
    );
}