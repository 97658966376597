export const patientConstants = {
    CREATE_REQUEST: 'PATIENTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PATIENTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PATIENTS_CREATE_FAILURE',

    GETALL_REQUEST: 'PATIENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PATIENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PATIENTS_GETALL_FAILURE',

    GETBYID_REQUEST: 'PATIENTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'PATIENTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PATIENTS_GETBYID_FAILURE',
};
