import { combineReducers } from 'redux';

import { registration } from './registrationReducer';
import { user } from './userReducer';
import { patient } from './patientReducer';

const rootReducer = combineReducers({
    user,
    registration,
    patient
});

export default rootReducer;