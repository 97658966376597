import { userConstants } from '../constants';
let userState = JSON.parse(localStorage.getItem('user'));
const initialState = userState ? { loggedIn: true, ...userState } : {};

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...action.user,
                loggingIn: true,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...action.user,
                loggedIn: true,
            };
        case userConstants.LOGIN_FAILURE:
            return { error: action.error };
        case userConstants.LOGOUT:
            return {};
        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
            };
        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                userName: action.user.userName,
                slotTime: parseInt(action.user.slotTime)
            };
        case userConstants.UPDATE_FAILURE:
            return {};
        case userConstants.GETALL_REQUEST:
            return {
                ...state
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                users: action.users
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.GETPATIENTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GETPATIENTS_SUCCESS:
            return {
                ...state,
                patients: action.patients
            };
        case userConstants.GETPATIENTS_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.ADDPATIENTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.ADDPATIENTS_SUCCESS:
            return {
                ...state,
                patients: [...state.patients, action.patient]
            };
        case userConstants.ADDPATIENTS_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.UPDATEPATIENTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.UPDATEPATIENTS_SUCCESS:
            const newArray = [...state.patients];
            const index = state.patients.findIndex(element => element._id === action.updatedPatient._id);
            newArray[index] = action.updatedPatient;
            return {
                ...state,
                patients: newArray
            };
        case userConstants.UPDATEPATIENTS_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.DELETE_REQUEST:
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case userConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.filter(user => user.id !== action.id)
            };
        case userConstants.DELETE_FAILURE:
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        const { deleting, ...userCopy } = user;
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        default:
            return state
    }
}