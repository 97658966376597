import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Mode, Save, Visibility, VisibilityOff, Upload } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

import logo from '../../Assets/logo.png';

import { userActions } from '../../Redux/actions/userActions';
import { userService, fileService } from '../../Redux/services';
import { RootState, UserState } from '../../Types';
import { DefaultSnackbar } from '../../Components';

import './Settings.css'

export default function Settings() {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);
    const [user, setUser] = useState<Partial<UserState>>({});
    const [edit, setEdit] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hide, setHide] = useState<boolean>(true);
    const [image, setImage] = useState(null)
    const [storedImage, setStoredImage] = useState(null)

    useEffect(() => {
        userService.getById(userId).then((resp) => {
            setUser(resp)
        })
        fileService.getByOwnerId(userId).then((resp) => {
            resp ? setStoredImage(resp[0]) : setStoredImage(null)
        })
    }, [userId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [event.target.id]: event.target.value });
    }

    const handleValidation = () => {
        let formIsValid = true;

        if (!user.firstname)
            formIsValid = false;

        if (!user.name)
            formIsValid = false;

        if (!user.email)
            formIsValid = false;

        // if (typeof user.firstname !== "undefined") {
        //     if (!user.firstname.match(/^[a-zA-Z]+$/))
        //         formIsValid = false;
        // }

        // if (typeof user.name !== "undefined") {
        //     if (!user.name.match(/^[a-zA-Z]+$/))
        //         formIsValid = false;
        // }

        if (typeof user.email !== "undefined") {
            let lastAtPos = user.email.lastIndexOf('@');
            let lastDotPos = user.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && user.email.indexOf('@@') === -1 && lastDotPos > 2 && (user.email.length - lastDotPos) > 2)) {
                formIsValid = false;
            }
        }

        if (newPassword !== '') {
            if (newPassword !== confirmPassword)
                formIsValid = false;
        }

        return formIsValid;
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            setEdit(!edit)
            if (image) {
                const form = new FormData();
                const data = {
                    ownerId: userId,
                    unique: true
                }
                form.append('file', image)
                form.append('props', JSON.stringify(data))
                fileService.create(form)
            }
            if (newPassword !== '') {
                if (newPassword === confirmPassword) {
                    const userUpdated = { ...user, password: newPassword };
                    dispatch(userActions.update(userUpdated));
                    setNewPassword('');
                    setConfirmPassword('');
                    enqueueSnackbar('Profil modifié !')
                }
            }
            else {
                dispatch(userActions.update(user));
                enqueueSnackbar('Profil modifié !')
            }
        }
        else {
            enqueueSnackbar("Formulaire incorrect.")
        }
    }

    return (
        <div className='settings-container'>
            <DefaultSnackbar />
            <div className='settings-content'>
                <div className='settings-row'>
                    <div className='settings-logo'>
                        <img src={image ? URL.createObjectURL(image) : storedImage ? `data:${storedImage.content.contentType};base64,${storedImage.content.data}` : logo} alt="Logo" />
                        {edit ? <IconButton sx={{ color: "#7F976C", position: 'absolute', bottom: '0px', right: '0px' }} component="label">
                            <input hidden name="file" accept="image/*" type="file" onChange={(e) => setImage(e.target.files[0])} />
                            <Upload />
                        </IconButton> : null}
                    </div>
                    {user.demo ? null : <IconButton onClick={() => edit ? handleSubmit() : setEdit(!edit)}>{edit ? <Save sx={{ color: "#000" }} /> : <Mode sx={{ color: "#000" }} />}</IconButton>}
                </div>
                <div className='settings-row'>
                    <TextField id="firstname" label="Prénom" variant="standard" fullWidth value={user.firstname ? user.firstname : ''} onChange={handleChange}
                        InputProps={{
                            readOnly: !edit,
                        }} />
                    <TextField id="name" label="Nom" variant="standard" fullWidth value={user.name ? user.name : ''} onChange={handleChange}
                        InputProps={{
                            readOnly: !edit,
                        }} />
                </div>
                <TextField id="email" label="Mail" variant="standard" fullWidth value={user.email ? user.email : ''} onChange={handleChange}
                    InputProps={{
                        readOnly: !edit,
                    }} />
                {user.demo ? null : <div className='settings-row'>
                    <TextField id="newPassword" label="Nouveau mot de passe" type={hide ? 'password' : 'text'} variant="standard" fullWidth value={newPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                        InputProps={{
                            readOnly: !edit,
                            endAdornment: (
                                newPassword !== '' ?
                                    < InputAdornment position="end" >
                                        <IconButton
                                            onClick={() => setHide(!hide)}
                                            edge="end"
                                        >
                                            {hide ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                            )
                        }}
                        InputLabelProps={{ shrink: newPassword !== '' ? true : false }}
                    />
                    <TextField id="confirmPassword" label="Confirmer mot de passe" type="password" variant="standard" fullWidth value={confirmPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            readOnly: !edit,
                        }}
                        InputLabelProps={{ shrink: confirmPassword !== '' ? true : false }} />
                </div>}
                <div className='settings-row' style={{ justifyContent: 'flex-start' }}>
                    Durée d'un créneau :
                    <TextField id="slotTime" variant="standard" value={user.slotTime ? user.slotTime : ''} type='number' onChange={handleChange} sx={{ width: "60px" }}
                        InputProps={{
                            readOnly: !edit,
                        }}
                        inputProps={{ min: 0, style: { textAlign: 'center' } }} />
                    minutes
                </div>
            </div>
        </div >
    );
}