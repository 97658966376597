import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  ExpandMore,
  East,
  Add,
  InsertPhoto,
  PictureAsPdf,
  Clear,
  Healing,
  Masks,
  Medication,
} from "@mui/icons-material";

import { HistoryField } from "../../Components";
import {
  patientService,
  fileService,
  traumaService,
  surgeryService,
  medicService,
} from "../../Redux/services";
import { PatientState, ConsultationState } from "../../Types";

import "./PatientInfo.css";

type Props = {
  userId: string;
  id: string;
  patient: Partial<PatientState>;
  setPatient: Function;
  edit: boolean;
  consultations: ConsultationState[];
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 2,
            "@media screen and (max-width: 600px)": {
              paddingX: 1,
            },
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `panel-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export function PatientInfo({
  userId,
  id,
  patient,
  setPatient,
  edit,
  consultations,
}: Props): JSX.Element {
  const [index, setIndex] = useState<number>(0);
  const [files, setFiles] = useState([]);
  const [traumas, setTraumas] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [medics, setMedics] = useState([]);

  useEffect(() => {
    fileService.getByOwnerId(id).then((resp) => {
      setFiles(resp);
    });
    traumaService.getByUserId(userId).then((resp) => {
      setTraumas(resp);
    });
    surgeryService.getByUserId(userId).then((resp) => {
      setSurgeries(resp);
    });
    medicService.getByUserId(userId).then((resp) => {
      setMedics(resp);
    });
  }, [userId, id]);

  const handleChange = (event) => {
    if (edit) {
      const target = event.target.id ? event.target.id : event.target.name;
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      setPatient({ ...patient, [target]: value });
    }
  };

  const handleChangeHistory = (event) => {
    let temp = patient.history;
    temp[event.target.id] = event.target.value;
    setPatient({ ...patient, history: temp });
  };

  const handleChangeIndex = (event, newValue) => {
    setIndex(newValue);
  };

  const handleAddTrauma = (data) => {
    let temp = patient.history;
    temp.trauma.push(data);
    setPatient({ ...patient, history: temp });
  };

  const handleChangeTrauma = (e, oldData, newData) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.trauma.findIndex((trauma) => {
      return trauma === oldData;
    });
    if (i !== -1) temp.trauma[i] = newData;
    setPatient({ ...patient, history: temp });
  };

  const handleRemoveTrauma = (e, data) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.trauma.findIndex((trauma) => {
      return trauma === data;
    });
    if (i !== -1) temp.trauma.splice(i, 1);
    setPatient({ ...patient, history: temp });
  };

  const handleAddSurgery = (data) => {
    let temp = patient.history;
    temp.surgery.push(data);
    setPatient({ ...patient, history: temp });
  };

  const handleChangeSurgery = (e, oldData, newData) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.surgery.findIndex((surgery) => {
      return surgery === oldData;
    });
    if (i !== -1) temp.surgery[i] = newData;
    setPatient({ ...patient, history: temp });
  };

  const handleRemoveSurgery = (e, data) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.surgery.findIndex((surgery) => {
      return surgery === data;
    });
    if (i !== -1) temp.surgery.splice(i, 1);
    setPatient({ ...patient, history: temp });
  };

  const handleAddMedic = (data) => {
    let temp = patient.history;
    temp.medic.push(data);
    setPatient({ ...patient, history: temp });
  };

  const handleChangeMedic = (e, oldData, newData) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.medic.findIndex((medic) => {
      return medic === oldData;
    });
    if (i !== -1) temp.medic[i] = newData;
    setPatient({ ...patient, history: temp });
  };

  const handleRemoveMedic = (e, data) => {
    if (e) e.preventDefault();
    let temp = patient.history;
    let i = temp.medic.findIndex((medic) => {
      return medic === data;
    });
    if (i !== -1) temp.medic.splice(i, 1);
    setPatient({ ...patient, history: temp });
  };

  const handleAddFile = (e, file) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", file);
    data.append("ownerId", id);
    fileService
      .create(data)
      .then((resp) => {
        addFile(resp.file);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addFile = (file) => {
    setFiles((files) => [...files, file]);
  };

  const handleOpenFile = (e, file) => {
    e.preventDefault();
    var w = window.open("");
    var url = `data:${file.content.contentType};base64,`;
    if (file.content.contentType.includes("image")) {
      var image = new Image();
      image.src = url + file.content.data;
      w.document.write(image.outerHTML);
    } else {
      let html = "";
      html += "<html>";
      html += '<body style="margin:0!important">';
      html +=
        '<embed width="100%" height="100%" src="' +
        url +
        encodeURI(file.content.data) +
        '" type="application/pdf" />';
      html += "</body>";
      html += "</html>";
      w.document.write(html);
      // var tmp = new Blob([file.content.data], { type: 'application/pdf' });
      // var fileURL = URL.createObjectURL(tmp);
      // window.open(fileURL)
    }
    w.document.title = file.name;
  };

  const handleDeleteFile = (e, file) => {
    e.preventDefault();
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    fileService
      .delete(file._id)
      .then((resp) => {
        deleteFile(file._id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteFile = (id) => {
    let updatedFiles = [...files];
    let i = files.findIndex((element) => {
      return element._id === id;
    });
    if (i !== -1) updatedFiles.splice(i, 1);
    setFiles(updatedFiles);
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette fiche patient ?"
    );

    if (confirmDelete) {
      patientService
        .remove(patient._id)
        .then((resp) => {
          window.location.href = `/patients`;
        })
        .catch((error) => {
          alert("Vous ne pouvez pas supprimer de patient.");
        });
    }
  };

  return (
    <>
      <div className="patientInfo-content">
        <TextField
          id="birthday"
          className="patientInfo-item"
          label="Date de naissance"
          variant="standard"
          value={patient.birthday ? patient.birthday : ""}
          onChange={handleChange}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <div className="patientInfo-row">
          <TextField
            id="phone"
            className="patientInfo-item"
            label="Téléphone"
            variant="standard"
            value={patient.phone ? patient.phone : ""}
            onChange={handleChange}
            InputProps={{
              readOnly: !edit,
            }}
          />
          <TextField
            id="mail"
            className="patientInfo-item"
            label="Email"
            variant="standard"
            value={patient.mail ? patient.mail : ""}
            onChange={handleChange}
            InputProps={{
              readOnly: !edit,
            }}
          />
        </div>
        <TextField
          id="job"
          label="Profession"
          variant="standard"
          value={patient.job ? patient.job : ""}
          onChange={handleChange}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <TextField
          id="address"
          label="Adresse"
          variant="standard"
          value={patient.address ? patient.address : ""}
          onChange={handleChange}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <TextField
          id="doctor"
          label="Médecin traitant"
          variant="standard"
          value={patient.doctor ? patient.doctor : ""}
          onChange={handleChange}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <TextField
          id="sport"
          label="Sport"
          variant="standard"
          value={patient.sport ? patient.sport : ""}
          onChange={handleChange}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <TextField
          id="posture"
          label="Posture"
          variant="standard"
          value={patient.posture ? patient.posture : ""}
          onChange={handleChange}
          InputProps={{
            readOnly: !edit,
          }}
        />
        <FormControl className="patientInfo-row">
          <FormControlLabel
            label="Semelles"
            control={
              <Checkbox
                id="soles"
                checked={patient.soles ? patient.soles : false}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
          />
          <TextField
            id="solesDetails"
            variant="standard"
            value={patient.solesDetails}
            onChange={handleChange}
            sx={{ width: "80%" }}
            multiline
            InputProps={{
              readOnly: !edit,
            }}
          />
        </FormControl>
        <TextField
          id="expectations"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          value={patient.notes}
          onChange={handleChange}
          placeholder="Notes..."
          InputProps={{
            readOnly: !edit,
          }}
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={index} onChange={handleChangeIndex} variant="fullWidth">
            <Tab label="Antécédents" {...a11yProps(0)} />
            <Tab label="Séances" {...a11yProps(1)} />
            <Tab label="Docs" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={index} index={0}>
          <div className="history-panel">
            <div style={{ position: "relative" }}>
              <div className="historyField-title">Traumatiques</div>
              <Healing
                sx={{
                  position: "absolute",
                  top: "-12px",
                  right: "0px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
              <div className="historyField-container">
                {edit && (
                  <HistoryField
                    key={"trauma"}
                    data={null}
                    type="Trauma"
                    items={traumas}
                    setItems={setTraumas}
                    onAdd={handleAddTrauma}
                    onChange={handleChangeTrauma}
                    onRemove={handleRemoveTrauma}
                    edit={edit}
                  />
                )}
                {patient.history && patient.history.trauma.length > 0 ? (
                  patient.history.trauma.map((trauma, index) => (
                    <HistoryField
                      key={"trauma-" + index}
                      data={trauma}
                      type="Trauma"
                      items={traumas}
                      setItems={setTraumas}
                      onAdd={handleAddTrauma}
                      onChange={handleChangeTrauma}
                      onRemove={handleRemoveTrauma}
                      edit={edit}
                    />
                  ))
                ) : edit ? null : (
                  <p>Rien à signaler</p>
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="historyField-title">Chirurgicaux</div>
              <Masks
                sx={{
                  position: "absolute",
                  top: "-12px",
                  right: "0px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
              <div className="historyField-container">
                {edit && (
                  <HistoryField
                    key={"surgery"}
                    data={null}
                    type="Surgery"
                    items={surgeries}
                    setItems={setSurgeries}
                    onAdd={handleAddSurgery}
                    onChange={handleChangeSurgery}
                    onRemove={handleRemoveSurgery}
                    edit={edit}
                  />
                )}
                {patient.history && patient.history.surgery.length > 0 ? (
                  patient.history.surgery.map((surgery, index) => (
                    <HistoryField
                      key={"surgery-" + index}
                      data={surgery}
                      type="Surgery"
                      items={surgeries}
                      setItems={setSurgeries}
                      onAdd={handleAddSurgery}
                      onChange={handleChangeSurgery}
                      onRemove={handleRemoveSurgery}
                      edit={edit}
                    />
                  ))
                ) : edit ? null : (
                  <p>Rien à signaler</p>
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="historyField-title">Médicaux</div>
              <Medication
                sx={{
                  position: "absolute",
                  top: "-12px",
                  right: "0px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
              <div className="historyField-container">
                {edit && (
                  <HistoryField
                    key={"medic"}
                    data={null}
                    type="Medic"
                    items={medics}
                    setItems={setMedics}
                    onAdd={handleAddMedic}
                    onChange={handleChangeMedic}
                    onRemove={handleRemoveMedic}
                    edit={edit}
                  />
                )}
                {patient.history && patient.history.medic.length > 0 ? (
                  patient.history.medic.map((medic, index) => (
                    <HistoryField
                      key={"medic-" + index}
                      data={medic}
                      type="Medic"
                      items={medics}
                      setItems={setMedics}
                      onAdd={handleAddMedic}
                      onChange={handleChangeMedic}
                      onRemove={handleRemoveMedic}
                      edit={edit}
                    />
                  ))
                ) : edit ? null : (
                  <p>Rien à signaler</p>
                )}
              </div>
            </div>
            <TextField
              id="treatment"
              label="Traitements Médicaux"
              multiline
              maxRows={5}
              value={patient.history ? patient.history.treatment : ""}
              onChange={handleChangeHistory}
              InputProps={{
                readOnly: !edit,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={index} index={1}>
          <div className="history-panel">
            {consultations.length > 0 &&
              consultations.map((consultation, index) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      id={"consultation-" + consultation._id}
                    >
                      <Typography
                        style={{ paddingRight: "3ch", width: "220px" }}
                      >
                        <b>Date :</b>{" "}
                        {new Intl.DateTimeFormat("fr-FR", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        }).format(new Date(consultation.date))}
                      </Typography>
                      <Typography>
                        <b>Motif :</b> {consultation.motif}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="history-panel-consults">
                      <Typography style={{ whiteSpace: "pre-line" }}>
                        {consultation.motifTotal}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          (window.location.href = `/consultation/${patient._id}/${consultation._id}`)
                        }
                        style={{ alignSelf: "flex-end" }}
                      >
                        <East sx={{ color: "#000" }} />
                      </IconButton>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        </TabPanel>
        <TabPanel value={index} index={2}>
          <div className="history-panel">
            {files.length > 0 &&
              files.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="patientInfo-file-container"
                    onClick={(e) => handleOpenFile(e, file)}
                  >
                    <div className="patientInfo-file-content">
                      {file.content.contentType.includes("image") ? (
                        <InsertPhoto />
                      ) : (
                        <PictureAsPdf />
                      )}
                      {file.name}
                      <IconButton
                        sx={{ marginLeft: "auto" }}
                        onClick={(e) => handleDeleteFile(e, file)}
                      >
                        <Clear sx={{ color: "#ff0000" }} />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            <IconButton
              sx={{
                height: "42px",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: "black",
                  color: "#7F976C",
                },
              }}
              component="label"
            >
              <input
                hidden
                name="file"
                accept="image/*,application/pdf"
                type="file"
                onChange={(e) => handleAddFile(e, e.target.files[0])}
              />
              <Add />
            </IconButton>
          </div>
        </TabPanel>
      </Box>
      <button className="patient-button delete" onClick={handleDelete}>
        Supprimer la fiche patient
      </button>
    </>
  );
}
