import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { frFR } from "@mui/material/locale";

import App from "./App";
import { store, persistor } from "./Redux/store";

const theme = createTheme({}, frFR);

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  )
})
