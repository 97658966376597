import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage,
  LastPage,
  Close,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";

import useClasses from "../../Components/UseClasses";
import { patientService, consultationService } from "../../Redux/services";
import { RootState, PatientState } from "../../Types";

import "./Patients.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Patients() {
  const classes = useClasses(styles);
  const user = useSelector((state: RootState) => state.user);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState<boolean>(false);
  const [patientParams, setPatientparams] = useState<PatientState>({
    active: true,
    firstname: "",
    name: "",
    sexe: "",
    birthday: "",
    phone: "",
    mail: "",
    doctor: "",
    job: "",
    address: "",
    sport: "",
    posture: "",
    soles: false,
    solesDetails: "",
    notes: "",
    history: {
      trauma: [],
      surgery: [],
      medic: [],
      treatment: "",
    },
    userId: user.userId,
  });
  const [items, setItems] = useState(
    useSelector((state: RootState) => state.user.patients)
  );
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  useEffect(() => {
    items.forEach((item, index) => {
      consultationService.getByPatientId(item._id).then((resp) => {
        if (resp.length > 0) {
          let tmp = [...items];
          tmp[index].last = resp[0].date;
          setItems(tmp);
        }
      });
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target.id ? event.target.id : event.target.name;
    setPatientparams({ ...patientParams, [target]: event.target.value });
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!patientParams.firstname) formIsValid = false;

    // if (typeof patientParams.firstname !== "undefined") {
    //     if (!patientParams.firstname.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    if (!patientParams.name) formIsValid = false;

    // if (typeof patientParams.name !== "undefined") {
    //     if (!patientParams.name.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    // if (!patientParams.mail) {
    //     formIsValid = false;
    // }

    // if (typeof patientParams.mail !== "undefined") {
    //     let lastAtPos = patientParams.mail.lastIndexOf('@');
    //     let lastDotPos = patientParams.mail.lastIndexOf('.');

    //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && patientParams.mail.indexOf('@@') === -1 && lastDotPos > 2 && (patientParams.mail.length - lastDotPos) > 2)) {
    //         formIsValid = false;
    //     }
    // }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      patientService
        .create(patientParams)
        .then((resp) => {
          window.location.href = `/patients/${resp.id}`;
        })
        .catch((error) => {
          alert("Vous ne pouvez pas créer de patient en mode démo.");
        });
    } else {
      alert("Formulaire incorrect.");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div
        style={{
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  }

  const renderBody = (
    <div style={modalStyle} className={classes.paper}>
      <h2 className={classes.titleModal}>Creation Patient</h2>
      <IconButton onClick={handleClose} className="patients-closeButton">
        <Close />
      </IconButton>
      <div className={classes.contentModal}>
        <TextField
          required
          id="firstname"
          label="Prénom"
          value={patientParams.firstname}
          onChange={handleChange}
          className={classes.item}
        />
        <TextField
          required
          id="name"
          label="Nom"
          value={patientParams.name}
          onChange={handleChange}
          className={classes.item}
        />
        <FormControl className={classes.item}>
          <InputLabel id="select-label">Sexe</InputLabel>
          <Select
            labelId="select-label"
            id="sexe"
            name="sexe"
            label="Sexe"
            defaultValue={patientParams.sexe}
            onChange={handleChange}
          >
            <MenuItem value={"Homme"}>Homme</MenuItem>
            <MenuItem value={"Femme"}>Femme</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.item}>
          <TextField
            id="birthday"
            label="Naissance"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={patientParams.birthday}
            onChange={handleChange}
          />
        </FormControl>
        <TextField
          id="phone"
          label="Téléphone"
          value={patientParams.phone}
          onChange={handleChange}
          className={classes.item}
        />
        <TextField
          id="mail"
          label="Mail"
          value={patientParams.mail}
          onChange={handleChange}
          className={classes.item}
          type="email"
        />
        <TextField
          id="doctor"
          label="Médecin"
          value={patientParams.doctor}
          onChange={handleChange}
          className={classes.item}
        />
        <TextField
          id="job"
          label="Profession"
          value={patientParams.job}
          onChange={handleChange}
          className={classes.item}
        />
        <TextField
          id="address"
          label="Adresse"
          value={patientParams.address}
          onChange={handleChange}
          className={classes.item1}
        />
      </div>
      <button className="patients-input" onClick={handleSubmit}>
        Sauvegarder
      </button>
    </div>
  );

  return (
    <div className={classes.root} style={{ paddingTop: "10px" }}>
      <IconButton aria-label="add" onClick={handleOpen}>
        <AddIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        {renderBody}
      </Modal>
      <TableContainer style={{ overflow: "auto", height: "calc(100% - 90px)" }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} align="left">
                Prénom
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Nom
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Sexe
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Téléphone
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                Dernière Consultation
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    key={row._id}
                    hover
                    component={Link}
                    to={`/patients/${row._id}`}
                  >
                    <TableCell align="left">{row.firstname}</TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      {row.sexe ? row.sexe : "Inconnu"}
                    </TableCell>
                    <TableCell align="left">
                      {row.phone ? row.phone : "Inconnu"}
                    </TableCell>
                    <TableCell align="left">
                      {row.last
                        ? new Intl.DateTimeFormat("fr-FR", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(row.last))
                        : "Aucune séance"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ height: "50px" }}>
        <TablePagination
          count={items.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          SelectProps={{
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
          style={{ border: "none" }}
        />
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 70px)",
    justifyItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    height: "80%",
    width: "98%",
    [theme.breakpoints.up("sm")]: {
      width: 600,
      height: 530,
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  createButton: {
    backgroundColor: "#2980B9",
    color: "white",
  },
  titleModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentModal: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "70%",
  },
  item: {
    width: "45%",
  },
  item1: {
    width: "100%",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#EDEDED",
  },
});
