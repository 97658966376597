import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const consultationService = {
    create,
    getAll,
    getById,
    getByPatientId,
    update,
    delete: _delete,
};

function create(consultation) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(consultation)
    };
    return fetch(`${REACT_APP_API_URL}/consultations`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/consultations`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/consultations/${id}`, requestOptions).then(handleResponse);
}

function getByPatientId(patientId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/consultations/patient/${patientId}`, requestOptions).then(handleResponse);
}

function update(consultation) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(consultation)
    };
    return fetch(`${REACT_APP_API_URL}/consultations/${consultation._id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/consultations/${id}`, requestOptions).then(handleResponse);
}
