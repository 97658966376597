import { useEffect, useState, useCallback, useRef } from 'react';

import { HistoryTimeline } from '../';

import { ConsultationState, PatientState } from '../../Types';

import { ReactComponent as Global } from '../../Assets/global.svg';
import { ReactComponent as Head } from '../../Assets/head.svg';
import { ReactComponent as Organs } from '../../Assets/organs.svg';

import './Schema.css'

type Props = {
    consultation: ConsultationState | null;
    isConsultation: boolean,
    patient?: Partial<PatientState>;
    edit?: boolean,
    setConsultation?: Function;
}

export function Schema({ consultation, isConsultation, patient, edit, setConsultation }: Props): JSX.Element {
    const [schema, setSchema] = useState<string>("global");
    const [clicked, setClicked] = useState<string>('');
    const [hovered, setHovered] = useState(null);
    const [currentElement, setCurrentElement] = useState(null);
    const [schemaId, setSchemaId] = useState<string>('schema_patient');
    var bgElement = document.getElementById('focus-bg');
    var clickElement = document.getElementById('infos-click');
    var clickElementButtons = document.getElementById('infos-click-buttons');
    var elements = document.querySelectorAll('g');
    const textInput = useRef(null);

    useEffect(() => {
        document.getElementsByClassName(`global-picture`)[0].className += " selected";
    }, [])

    useEffect(() => {
        const global = document.getElementsByClassName(`schema-svg-global`) as HTMLCollectionOf<HTMLElement>;
        const head = document.getElementsByClassName(`schema-svg-head`) as HTMLCollectionOf<HTMLElement>;
        const organs = document.getElementsByClassName(`schema-svg-organs`) as HTMLCollectionOf<HTMLElement>;
        if (isConsultation && !consultation._id) {
            setSchemaId('schema_consultation');
            if (global[0])
                global[0].style.width = '70%';
            if (head[0])
                head[0].style.width = '80%';
            if (organs[0])
                organs[0].style.width = '70%';
        }
        else {
            if (global[0])
                global[0].style.width = '55%';
            if (head[0])
                head[0].style.width = '70%';
            if (organs[0])
                organs[0].style.width = '55%';
        }
    }, [isConsultation, schema])

    useEffect(() => {
        if (consultation) {
            if (consultation.workedOn.global.length > 0)
                document.getElementsByClassName(`global-picture`)[0].className += " worked";
            else
                document.getElementsByClassName(`global-picture`)[0].className = document.getElementsByClassName(`global-picture`)[0].className.replace(" worked", '');
            if (consultation.workedOn.head.length > 0)
                document.getElementsByClassName(`head-picture`)[0].className += " worked";
            else
                document.getElementsByClassName(`head-picture`)[0].className = document.getElementsByClassName(`head-picture`)[0].className.replace(" worked", '');
            if (consultation.workedOn.organs.length > 0)
                document.getElementsByClassName(`organs-picture`)[0].className += " worked";
            else
                document.getElementsByClassName(`organs-picture`)[0].className = document.getElementsByClassName(`organs-picture`)[0].className.replace(" worked", '');
        }
    }, [consultation, schema])

    useEffect(() => {
        elements = document.querySelectorAll('g');
        if (consultation) {
            elements.forEach((element) => {
                element.classList.remove("is-selected");
            })
            if (consultation.workedOn[schema].length > 0) {
                consultation.workedOn[schema].forEach(function (el) {
                    document.getElementById(el.split(' ')[0]).classList.add("is-selected");
                })
            }
        }
    }, [schema, consultation])

    const handleClick = (e) => {
        if (consultation && isConsultation) {
            clickElement = document.getElementById('infos-click');
            clickElementButtons = document.getElementById('infos-click-buttons');
            bgElement = document.getElementById('focus-bg');
            let element = e.target.parentElement;
            setCurrentElement(element);
            var tmp = consultation.workedOn;
            if (element.classList.contains("is-selected")) {
                element.classList.remove("is-selected");
                setClicked('');
                var i = consultation.workedOn[schema].findIndex(el => el.split(' ')[0] === element.id);
                if (i !== -1) {
                    tmp[schema].splice(i, 1);
                    setConsultation({ ...consultation, workedOn: tmp });
                }
            }
            else {
                element.classList.add("is-selected");
                setClicked('');
                let X = window.innerWidth > 600 ? (e.clientX - 340) + 'px' : "50%";
                let Y = window.innerWidth > 600 ? (e.clientY - 20) + 'px' : "50%";
                clickElement.style.top = Y;
                clickElement.style.left = X;
                clickElement.style.display = "flex";
                if (window.innerWidth <= 600)
                    clickElement.style.transform = `translate(-50%, -50%)`;
                clickElementButtons.style.display = "flex";
                bgElement.style.display = "block";
                tmp[schema].push(element.id);
                textInput.current.focus();
                setConsultation({ ...consultation, workedOn: tmp });
            }
        }
    };

    const handleMouseEnter = (e) => {
        let element = e.target;
        clickElement = document.getElementById('infos-click');
        clickElementButtons = document.getElementById('infos-click-buttons');
        if (element.classList.contains("is-selected") && consultation) {
            let [first, ...rest] = consultation.workedOn[schema].find(el => el.split(' ')[0] === element.id).split(' ');
            rest = rest.join(' ');
            setClicked(rest);
            if (rest) {
                let X = window.innerWidth > 600 ? (e.clientX - 340) + 'px' : "50%";
                let Y = window.innerWidth > 600 ? (e.clientY - 20) + 'px' : "50%";
                if (window.innerWidth <= 600)
                    clickElement.style.transform = `translate(-50%, -50%)`;
                clickElement.style.top = Y;
                clickElement.style.left = X;
                clickElement.style.display = "flex";
                if (isConsultation)
                    clickElementButtons.style.display = "none";
            }
        }
    };

    const handleMouseEnterHovered = (e) => {
        let element = e.target;
        if (window.innerWidth > 600)
            setHovered(element.id.replaceAll("-", " "));
    };

    const handleMouseLeave = useCallback((e) => {
        clickElement = document.getElementById('infos-click');
        if (clickElement && !edit)
            clickElement.style.display = "none";
    }, []);

    const handleMouseLeaveHovered = useCallback((e) => {
        setHovered(null);
    }, []);

    useEffect(() => {
        elements = document.querySelectorAll('g');
        if (consultation) {
            elements.forEach(function (element) {
                if (isConsultation) {
                    element.addEventListener("mouseenter", handleMouseEnterHovered);
                    element.addEventListener("mouseleave", handleMouseLeaveHovered);
                }
                if (edit && isConsultation) {
                    element.addEventListener("click", handleClick);
                }
                else {
                    element.addEventListener("mouseenter", handleMouseEnter)
                    element.addEventListener("mouseleave", handleMouseLeave)
                }
            });
            return () => {
                elements.forEach(function (element) {
                    element.removeEventListener("click", handleClick);
                    element.removeEventListener("mouseenter", handleMouseEnter);
                    element.removeEventListener("mouseleave", handleMouseLeave);
                    element.removeEventListener("mouseenter", handleMouseEnterHovered);
                    element.removeEventListener("mouseleave", handleMouseLeaveHovered);
                })
            }
        }
    }, [edit, schema, consultation])

    const handleClickSquare = (image, target) => {
        setSchema(image);
        var elements = document.getElementsByClassName('schema-square');
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove('selected');
        }
        document.getElementsByClassName(`${target}`)[0].className += " selected";
    }

    const handleClickChange = event => {
        setClicked(event.target.value);
    }

    const handleClickSave = () => {
        var tmp = consultation.workedOn;
        var i = consultation.workedOn[schema].findIndex(el => el.split(' ')[0] === currentElement.id);
        if (i !== -1) {
            tmp[schema].splice(i, 1);
            tmp[schema].push(currentElement.id + ' ' + clicked);
            setConsultation({ ...consultation, workedOn: tmp });
        }
        bgElement.style.display = "none";
        clickElement.style.display = "none";
    }

    const handleClickCancel = () => {
        currentElement.classList.remove("is-selected");
        var tmp = consultation.workedOn;
        var i = consultation.workedOn[schema].findIndex(el => el.split(' ')[0] === currentElement.id);
        if (i !== -1) {
            tmp[schema].splice(i, 1);
            setConsultation({ ...consultation, workedOn: tmp });
        }
        bgElement.style.display = "none";
        clickElement.style.display = "none";
    }

    const renderSwitch = (element) => {
        switch (element) {
            case 'global':
                return <div className="schema-svg schema-svg-global"><Global /></div>
            case 'head':
                return <div className="schema-svg schema-svg-head"><Head /></div>
            case 'organs':
                return <div className="schema-svg schema-svg-organs"><Organs /></div>
            default:
                return <div className="schema-svg schema-svg-global"><Global /></div>
        }
    }

    return (
        <div id={schemaId} className='schema'>
            <div id="focus-bg" className='focus-bg' />
            <div>
                {!isConsultation ? <div><b>Date de la dernière séance :</b> {consultation ? new Intl.DateTimeFormat("fr-FR", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                }).format(new Date(consultation.date)) : 'Aucune consultation'}
                </div> : null}
                {consultation && !isConsultation ? <div><b>Motif :</b> {consultation.motif}</div> : null}
                <div className='schema-line'>
                    <button className='schema-square global-picture' onClick={() => handleClickSquare("global", "global-picture")} />
                    <button className='schema-square head-picture' onClick={() => handleClickSquare("head", "head-picture")} />
                    <button className='schema-square organs-picture' onClick={() => handleClickSquare("organs", "organs-picture")} />
                </div>
            </div>
            <div className='schema-picture'>
                {renderSwitch(schema)}
                {hovered && isConsultation ? <div className='schema-info-hover'>{hovered}</div> : null}
            </div>
            <div id="infos-click" className='schema-info-clicked' >
                <textarea ref={textInput} className='schema-info-clicked-text' rows={5} cols={40} disabled={!edit} value={clicked} onChange={(e) => handleClickChange(e)} />
                {isConsultation ?
                    <div id="infos-click-buttons" className='schema-info-clicked-buttons'>
                        <button className='schema-info-clicked-button' onClick={() => handleClickSave()}>Sauvegarder</button>
                        <button className='schema-info-clicked-button' onClick={() => handleClickCancel()}>Annuler</button>
                    </div>
                    : null}
            </div>
            {patient && patient.history && !isConsultation && <HistoryTimeline data={patient.history} />}
        </div>
    );
}