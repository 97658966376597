import React from 'react';
import { IconButton } from '@mui/material';
import { Add, Person, Videocam, Subject, Send } from '@mui/icons-material'

import { AdviceState } from '../../Types';

import './AdviceCard.css'

type Props = {
    item: AdviceState;
    handleOpen: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, item?: AdviceState) => void;
    handleOpenMail?: (event: React.MouseEvent<HTMLButtonElement>, item?: AdviceState) => void;
}

export function AdviceCard({ item, handleOpen, handleOpenMail }: Props): JSX.Element {
    const renderType = (type: number) => {
        switch (type) {
            case 0:
                return <Person />
            case 1:
                return <Videocam />
            case 2:
                return <Subject />
            default:
                return <Person />
        }
    }

    const renderThumbnail = (type: number, description: string): JSX.Element | null => {
        const ytLink = "https://www.youtube.com/watch?v=";
        if (type === 1) {
            const indexId: number = description.indexOf(ytLink) + ytLink.length;
            if (indexId !== 31) {
                const videoId = description.slice(indexId, indexId + 11);
                return <img alt="Video thumbnail" src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`} style={{ width: "80%" }} />;
            }
        }
        return null;
    };

    return (
        <div>
            {
                item ?
                    <div className='adviceCardContainer'>
                        <div className='adviceCardHeader' >
                            <div className='adviceCardTitle' > {item.title} </div>
                            <div> {renderType(item.type)} </div>
                        </div>
                        {renderThumbnail(item.type, item.description)}
                        <div className='adviceCardDescription' onClick={(e) => handleOpen(e, item)} > {item.description} </div>
                        <button className='adviceCardButton' onClick={(e) => handleOpenMail(e, item)}> Partager < Send /> </button>
                    </div>
                    :
                    <IconButton
                        aria-label="add"
                        onClick={handleOpen}
                        className='adviceCardContainer'
                        style={{ justifyContent: 'center' }}
                    >
                        <Add sx={{ width: '50px', height: '50px' }} />
                    </IconButton>
            }
        </div>
    );
}