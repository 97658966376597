import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IconButton } from '@mui/material';
import { Mode, Save, ArrowBack } from '@mui/icons-material';

import { Anamnesis, Schema } from '../../Components';
import { consultationService } from '../../Redux/services';
import { RootState, ConsultationState } from '../../Types';

import './Consultation.css'

export default function Consultation() {
    const { patientId, id } = useParams();
    const user = useSelector((state: RootState) => state.user);
    const [consultation, setConsultation] = useState<ConsultationState>({
        date: new Date().toISOString().substring(0, 10),
        motif: '',
        motifTotal: '',
        happenedR: false,
        wakeUpR: false,
        medicationsR: false,
        irradiationsR: false,
        examsR: false,
        happened: '',
        wakeUp: '',
        medications: '',
        irradiations: '',
        exams: '',
        eva: '/10',
        head: '',
        chest: '',
        abdomen: '',
        pelvis: '',
        sleep: '',
        psychologic: '',
        expectations: '',
        workedOn: {
            global: [],
            head: [],
            organs: []
        },
        patientId: patientId,
        userId: user.userId
    });
    const [edit, setEdit] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            consultationService.getById(id).then((resp) => {
                if (resp) {
                    setConsultation(resp);
                    setEdit(false);
                }
                else
                    setEdit(true);
            });
        }
        else
            setEdit(true);
    }, [id]);

    const handleValidation = () => {
        let formIsValid = true;

        if (!consultation.motif)
            formIsValid = false;

        // if (typeof consultation.firstname !== "undefined") {
        //     if (!consultation.firstname.match(/^[a-zA-Z]+$/))
        //         formIsValid = false;
        // }

        if (!consultation.date)
            formIsValid = false;

        // if (typeof consultation.name !== "undefined") {
        //     if (!consultation.name.match(/^[a-zA-Z]+$/))
        //         formIsValid = false;
        // }

        // if (!consultationParams.mail) {
        //     formIsValid = false;
        //     errors["email"] = "Ne peut être vide.";
        // }

        // if (typeof fields["email"] !== "undefined") {
        //     let lastAtPos = fields["email"].lastIndexOf('@');
        //     let lastDotPos = fields["email"].lastIndexOf('.');

        //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        //         formIsValid = false;
        //         errors["email"] = "Email non valide.";
        //     }
        // }

        return formIsValid;
    }

    const handleSubmit = () => {
        if (consultation._id) {
            if (handleValidation()) {
                consultationService.update(consultation).catch((error) => { console.log(error) });
                setEdit(!edit);
            }
            else
                alert("Formulaire incorrect.");
        }
        else {
            if (handleValidation())
                consultationService.create(consultation)
                    .then(() => window.location.href = `/patients/${patientId}`)
                    .catch(() => { window.location.href = `/patients/${patientId}` });
            else
                alert("Formulaire incorrect.");
        }
    }

    return (
        <div className='consultation-container'>
            <div className='consultation-infos'>
                <div className='consultation-column'>
                    <div className='consultation-row consultation-space-between' style={{ paddingTop: "18px" }}>
                        <IconButton onClick={() => window.location.href = `/patients/${patientId}`} sx={{ padding: "0" }}><ArrowBack sx={{ color: "#000", width: "1.25em", height: "1.25em" }} /></IconButton>
                        {consultation._id ?
                            <IconButton onClick={() => edit ? handleSubmit() : setEdit(!edit)} sx={{ padding: "0" }}>{edit ? <Save sx={{ color: "#000", width: "1.25em", height: "1.25em" }} /> : <Mode sx={{ color: "#000", width: "1.25em", height: "1.25em" }} />}</IconButton>
                            : <div />}
                    </div>
                </div>
                <Anamnesis consultation={consultation} setConsultation={setConsultation} edit={edit} setEdit={setEdit} patientId={patientId} />
            </div>
            <Schema consultation={consultation} isConsultation={true} patient={undefined} edit={edit} setConsultation={setConsultation} />
        </div >
    );
}