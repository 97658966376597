import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { userService } from '../../Redux/services';
import picture from '../../Assets/login.png';
import './Forgot.css'

export default function Forgot() {
    const [email, setEmail] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        if (email) {
            userService.requestResetPassword(email).then(() => { window.location.href = `/login` })
        } else alert("Veuillez indiquer votre email");
    }

    return (
        <div className="forgotContainer">
            <div className="forgotContainer-left">
                <form className="forgotContent" onSubmit={handleSubmit}>
                    <IconButton sx={{ position: "absolute", top: "32px", left: "32px" }} onClick={() => window.location.href = `/`}><ArrowBack sx={{ color: "#000", width: "50px", height: "auto" }} /></IconButton>
                    <div className="title">Mot de passe oublié</div>
                    <div className="forgotBox">
                        <div>Veuillez indiquer l'adresse mail liée à votre compte, vous recevrez un lien pour réinitialiser votre mot de passe</div>
                        <div>Si vous avez oublié votre adresse mail, veuillez nous <a style={{ color: "#7F976C" }} href='mailto:contact@sesamoide.com'>contacter</a></div>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type='email'
                            onChange={e => setEmail(e.target.value)}
                            fullWidth
                        />
                    </div>
                    <button className="forgotButton" type="submit">Recevoir le lien</button>
                </form>
            </div>
            <div className="forgotContainer-right">
                <img className="picture" src={picture} alt="Page de connexion" />
            </div>
        </div>
    );
}