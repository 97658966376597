import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../Assets/wesamoide_waiting.json'
import './Accounting.css'

export default function Accounting() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='developpement-container'>
            <h2>En cours de développement</h2>
            <h3>Prochainement : Votre comptabilité !</h3>
            <Lottie options={defaultOptions}
                height={400}
                width={400} />
        </div >
    );
}