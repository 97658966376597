import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from "@mui/material";

import { consultationService, userService } from "../../Redux/services";
import { RootState, ConsultationState } from "../../Types";

import "./Anamnesis.css";

type Props = {
  consultation: ConsultationState | null;
  setConsultation: Function;
  edit: boolean;
  setEdit: Function;
  patientId: string;
  submitPatient?: Function;
};

export function Anamnesis({
  consultation,
  setConsultation,
  edit,
  setEdit,
  patientId,
  submitPatient,
}: Props): JSX.Element {
  const user = useSelector((state: RootState) => state.user);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    userService.getNameById(consultation.userId).then((value) => {
      setUserName(value);
    });
  }, [consultation]);

  const handleChange = (event) => {
    if (edit) {
      const target = event.target.id ? event.target.id : event.target.name;
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      setConsultation({ ...consultation, [target]: value });
    }
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!consultation.motif) formIsValid = false;

    // if (typeof consultation.firstname !== "undefined") {
    //     if (!consultation.firstname.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    if (!consultation.date) formIsValid = false;

    // if (typeof consultation.name !== "undefined") {
    //     if (!consultation.name.match(/^[a-zA-Z]+$/))
    //         formIsValid = false;
    // }

    // if (!consultationParams.mail) {
    //     formIsValid = false;
    //     errors["email"] = "Ne peut être vide.";
    // }

    // if (typeof fields["email"] !== "undefined") {
    //     let lastAtPos = fields["email"].lastIndexOf('@');
    //     let lastDotPos = fields["email"].lastIndexOf('.');

    //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
    //         formIsValid = false;
    //         errors["email"] = "Email non valide.";
    //     }
    // }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (consultation._id) {
      if (handleValidation()) {
        consultationService.update(consultation).catch((error) => {
          console.log(error);
        });
        setEdit(!edit);
      } else alert("Formulaire incorrect.");
    } else {
      if (handleValidation())
        consultationService
          .create(consultation)
          .then(() => {
            if (submitPatient) submitPatient();
            window.location.href = `/patients/${patientId}`;
          })
          .catch(() => {
            window.location.href = `/patients/${patientId}`;
          });
      else alert("Formulaire incorrect.");
    }
  };

  return (
    <div className="anamnesis-container">
      <div className="anamnesis-column">
        <div className="anamnesis-title anamnesis-row anamnesis-space-between">
          <FormControl
            className="anamnesis-row"
            sx={{ width: "50% !important" }}
          >
            <FormLabel
              id="date-label"
              sx={{ fontSize: "1.5rem", fontWeight: "bold", color: "black" }}
            >
              Date
            </FormLabel>
            <TextField
              id="date"
              variant="standard"
              value={consultation.date}
              onChange={handleChange}
              type="date"
              style={{ marginLeft: "16px", width: "13ch" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: !edit,
              }}
              inputProps={{ style: { fontWeight: "bold" } }}
            />
          </FormControl>
          <div className="anamnesis-userName">
            <b>Praticien :</b> {consultation._id ? userName : user.userName}
          </div>
        </div>
      </div>
      <FormControl className="anamnesis-row">
        <FormLabel id="motif-label">Motif</FormLabel>
        <TextField
          id="motif"
          variant="standard"
          fullWidth
          value={consultation.motif}
          onChange={handleChange}
          style={{ marginLeft: "16px" }}
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <TextField
        id="motifTotal"
        variant="outlined"
        placeholder="Motif détaillé..."
        fullWidth
        multiline
        rows={5}
        value={consultation.motifTotal}
        onChange={handleChange}
        sx={{ marginTop: "16px" }}
        InputProps={{
          readOnly: !edit,
        }}
      />
      <FormControl className="anamnesis-row anamnesis-space-between">
        <FormControlLabel
          label="Déja Survenu"
          control={
            <Checkbox
              id="happenedR"
              checked={consultation.happenedR}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
        />
        <TextField
          id="happened"
          variant="standard"
          value={consultation.happened}
          onChange={handleChange}
          sx={{ width: "70%" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row anamnesis-space-between">
        <FormControlLabel
          label="Réveil"
          control={
            <Checkbox
              id="wakeUpR"
              checked={consultation.wakeUpR}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
        />
        <TextField
          id="wakeUp"
          variant="standard"
          value={consultation.wakeUp}
          onChange={handleChange}
          sx={{ width: "70%" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row anamnesis-space-between">
        <FormControlLabel
          label="Médicaments"
          control={
            <Checkbox
              id="medicationsR"
              checked={consultation.medicationsR}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
        />
        <TextField
          id="medications"
          variant="standard"
          value={consultation.medications}
          onChange={handleChange}
          sx={{ width: "70%" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row anamnesis-space-between">
        <FormControlLabel
          label="Irradiation"
          control={
            <Checkbox
              id="irradiationsR"
              checked={consultation.irradiationsR}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
        />
        <TextField
          id="irradiations"
          variant="standard"
          value={consultation.irradiations}
          onChange={handleChange}
          sx={{ width: "70%" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row anamnesis-space-between">
        <FormControlLabel
          label="Examens"
          control={
            <Checkbox
              id="examsR"
              checked={consultation.examsR}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          sx={{ textWrap: "nowrap", opacity: edit ? "1" : "0.6" }}
        />
        <TextField
          id="exams"
          variant="standard"
          value={consultation.exams}
          onChange={handleChange}
          sx={{ width: "70%" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="eva-label">EVA</FormLabel>
        <TextField
          id="eva"
          variant="standard"
          value={consultation.eva}
          onChange={handleChange}
          style={{ marginLeft: "16px", width: "5ch" }}
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <h2>Systèmes</h2>
      <FormControl className="anamnesis-row">
        <FormLabel id="head-label" className="anamnesis-label">
          Tête
        </FormLabel>
        <TextField
          id="head"
          variant="standard"
          value={consultation.head}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="chest-label" className="anamnesis-label">
          Thorax
        </FormLabel>
        <TextField
          id="chest"
          variant="standard"
          value={consultation.chest}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="abdomen-label" className="anamnesis-label">
          Abdomen
        </FormLabel>
        <TextField
          id="abdomen"
          variant="standard"
          value={consultation.abdomen}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="pelvis-label" className="anamnesis-label">
          Pelvis
        </FormLabel>
        <TextField
          id="pelvis"
          variant="standard"
          value={consultation.pelvis}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="sleep-label" className="anamnesis-label">
          Sommeil
        </FormLabel>
        <TextField
          id="sleep"
          variant="standard"
          value={consultation.sleep}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <FormControl className="anamnesis-row">
        <FormLabel id="psychologic-label" className="anamnesis-label">
          Psychologique
        </FormLabel>
        <TextField
          id="psychologic"
          variant="standard"
          value={consultation.psychologic}
          onChange={handleChange}
          fullWidth
          style={{ marginLeft: "16px" }}
          multiline
          InputProps={{
            readOnly: !edit,
          }}
        />
      </FormControl>
      <h2>Conseils / Remarques</h2>
      <TextField
        id="expectations"
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        value={consultation.expectations}
        onChange={handleChange}
        InputProps={{
          readOnly: !edit,
        }}
      />
      {consultation._id ? null : (
        <div
          className="anamnesis-row anamnesis-center desktop"
          style={{ paddingTop: "16px" }}
        >
          <button className="anamnesis-input" onClick={handleSubmit}>
            Valider la séance
          </button>
        </div>
      )}
    </div>
  );
}
