import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField, FormControlLabel, Switch, IconButton } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, Clear } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

import {
  traumaService,
  surgeryService,
  medicService,
} from "../../Redux/services";
import { RootState } from "../../Types";

import "./HistoryField.css";

const filter = createFilterOptions();

export function HistoryField(props) {
  const user = useSelector((state: RootState) => state.user);
  const { data, edit, items, setItems } = props;
  const [value, setValue] = useState(null);
  const [date, setDate] = useState(null);
  const [important, setImportant] = useState(false);

  useEffect(() => {
    setValue(data ? data : null);
    setDate(data ? moment(data.date) : null);
    setImportant(data ? data.important : false);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onAdd({ label: value.label, date: date, important: important });
    setValue(null);
    setDate(moment());
    setImportant(false);
  };

  const addItem = (label) => {
    let newItem = { label: label, _id: uuidv4() };
    setItems((items) => [...items, newItem]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="historyField-content">
        <div className="historyField-row">
          <Autocomplete
            value={value}
            fullWidth
            onChange={(e, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  label: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                if (props.type === "Trauma") {
                  traumaService
                    .create({ label: newValue.inputValue, userId: user.userId })
                    .catch((error) => {
                      addItem({ label: newValue.inputValue });
                    });
                }
                if (props.type === "Surgery") {
                  surgeryService
                    .create({ label: newValue.inputValue, userId: user.userId })
                    .catch((error) => {
                      addItem({ label: newValue.inputValue });
                    });
                }
                if (props.type === "Medic") {
                  medicService
                    .create({ label: newValue.inputValue, userId: user.userId })
                    .catch((error) => {
                      addItem({ label: newValue.inputValue });
                    });
                }
                setValue({
                  label: newValue.inputValue,
                });
              } else {
                setValue(newValue);
              }
              props.onChange(e, data, {
                label: newValue.inputValue,
                date: date,
                important: important,
              });
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              if (inputValue.length <= 2) {
                return [];
              }
              const filtered = filter(options, params);
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Ajouter "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="history-autocomplete"
            options={items}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            renderOption={(props, option) => (
              <li key={option._id} {...props}>
                {option.label}
              </li>
            )}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
            freeSolo
            readOnly={!edit}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Libellé"
                fullWidth
                sx={{ input: { color: important ? "red" : "black" } }}
              />
            )}
          />
          <DatePicker
            views={["year", "month"]}
            openTo="year"
            format="MM/YYYY"
            disableFuture
            label="Date"
            value={date}
            readOnly={!edit}
            sx={{ width: "170px", paddingRight: 1 }}
            onChange={(newValue) => {
              setDate(newValue);
              if (value && value.label)
                props.onChange(null, data, {
                  label: value.label,
                  date: newValue,
                  important: important,
                });
            }}
          />
        </div>
        {edit ? (
          <div>
            <div className="historyField-row" style={{ marginTop: "-10px" }}>
              <FormControlLabel
                value="history-switch"
                control={
                  <Switch
                    checked={important}
                    onChange={(e, newValue) => {
                      setImportant(!important);
                      props.onChange(e, data, {
                        label: value.label,
                        date: date,
                        important: newValue,
                      });
                    }}
                  />
                }
                label="Important ?"
                labelPlacement="start"
              />
              {data ? (
                <IconButton
                  sx={{ marginLeft: 5 }}
                  onClick={(e) => props.onRemove(e, data)}
                >
                  <Clear sx={{ color: "#ff0000" }} />
                </IconButton>
              ) : (
                <IconButton sx={{ marginLeft: 5 }} onClick={handleSubmit}>
                  <Add sx={{ color: "#7F976C" }} />
                </IconButton>
              )}
            </div>
            <div className="historyField-border" />
          </div>
        ) : null}
      </div>
    </LocalizationProvider>
  );
}
