import { authHeader, handleResponse } from '../../Helpers';

const { REACT_APP_API_URL } = process.env;

export const meetingService = {
    create,
    getAll,
    getByUserId,
    update,
    delete: _delete,
};

function create(meeting) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(meeting)
    };
    return fetch(`${REACT_APP_API_URL}/meetings`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/meetings`, requestOptions).then(handleResponse);
}

function getByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/meetings/${userId}`, requestOptions).then(handleResponse);
}

function update(meeting) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(meeting)
    };
    return fetch(`${REACT_APP_API_URL}/meetings/${meeting._id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${REACT_APP_API_URL}/meetings/${id}`, requestOptions).then(handleResponse);
}