import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmBox(props) {
    const [open, setOpen] = useState(props.open);
    const text = useState(props.text);

    const handleClose = (value) => {
        setOpen(false);
        if (value === true)
            props.onConfirm()
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Changement de page"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(true)}>Oui</Button>
                <Button onClick={() => handleClose(false)}>Annuler</Button>
            </DialogActions>
        </Dialog>
    );
}